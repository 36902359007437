<template>
    <div class="seongdong_curation_wrap emotion morning" :class="getCurationCss">
        <ul class="setting_target_detail">
            <h2 class="curation_body_list_title"> 선호음악 분위기(3개 이상 선택) </h2>
            <li>
                <ul class="curation_list">
                    <li class="curation_list_item mb8" v-for="item in getEmotion" :key="item.id">
                        <label :for="item.id" class="curation_item" :class="emotion.includes(item.value) ? 'active' : ''">{{
                            item.koText
                        }}</label>
                        <input :id="item.id" type="checkbox" hidden :value="item.value" v-model="emotion">
                    </li>
                </ul>
            </li>

        </ul>
    </div>
</template>
<script>
export default {
  name: 'SeongDongCurationEmotion',
  emits: ["send-curation"],
  props: {
    emotionarray: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      emotion: this.emotionarray.map(item => this.$store.getters["player/getCurationStoreEmotion"](item))
    };
  },

  mounted () {

  },

  methods: {

  },
  computed: {
    getCurationCss () {
      return this.$store.getters.gettersCurationCssClass;
    },
    getEmotion () {
      return this.$store.getters['curation/getEmotion'];
    }
  },
  watch: {
    emotion: {
      deep: true,
      handler (val) {
        this.$emit('send-curation', {
          type: 'emotion',
          value: val
        });
      }
    }
  }
};
</script>
<style scoped src="@/assets/css/seongdonglibary/seongdongcuration.css"></style>
